
const Main = ({header, children, footer}) => {

  return (
    <>
      {header}
      {children}
      {footer}
    </>
  )
}

export default Main
